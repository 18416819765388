import { GettingEndpoint } from '@/domain/services/Api/Endpoint';
import { isFunction } from '@/domain/utils/TypePredicates';
import { RoutesGetter, useRoutesDao } from '@/domain/dao/Routes';

const useApiItemCount = () => {
	const routes = useRoutesDao<{
		cart: {
			items: {
				count: () => void;
			};
		};
	}>();

	const url = isFunction(routes?.cart?.items?.count) ? (routes?.cart?.items?.count as RoutesGetter)() : undefined;

	if (!url) {
		console.error('No route found for cart items count');
		return { getItemsCount: async () => undefined };
	}

	const getItemsCount = GettingEndpoint<{
		count: number;
	}>(url);

	return { getItemsCount };
};

export { useApiItemCount };
