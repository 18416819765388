
import AbstractCounterIcon from '@/lib/components/Shared/AbstractCounterIcon.vue';
import { announceKitIconController } from '@/lib/components/Shared/AnnounceKitIcon.controller';
import { defineComponent } from 'vue';
import { useController } from '@/domain/services/Composer';
import { CounterProps, CounterView } from '@/lib/components/Shared/Counter.composable';

export default defineComponent<CounterProps, CounterView>({
	extends: AbstractCounterIcon,
	setup: useController(announceKitIconController, 'AnnounceKitIconController') as () => CounterView,
});
