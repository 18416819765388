import { GettingEndpoint } from '@/domain/services/Api/Endpoint';
import { isFunction } from '@/domain/utils/TypePredicates';
import { RoutesGetter, useRoutesDao } from '@/domain/dao/Routes';

const useApiUnreadCount = () => {
	const routes = useRoutesDao<{
		updates: {
			unread: {
				count: () => void;
			};
		};
	}>();

	const url = isFunction(routes?.updates?.unread?.count)
		? (routes?.updates?.unread?.count as RoutesGetter)()
		: undefined;
	if (!url) {
		console.error('No route found for updates unread count');
		return { getUnreadCount: async () => undefined };
	}

	const getUnreadCount = GettingEndpoint<{
		unread: number;
	}>(url ?? (routes?.updates?.unread?.count as RoutesGetter));

	return { getUnreadCount };
};

export { useApiUnreadCount };
