import { render, staticRenderFns } from "./AbstractCounterIcon.vue?vue&type=template&id=58db4d5b&scoped=true"
import script from "./AbstractCounterIcon.vue?vue&type=script&lang=ts"
export * from "./AbstractCounterIcon.vue?vue&type=script&lang=ts"
import style0 from "./AbstractCounterIcon.vue?vue&type=style&index=0&id=58db4d5b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58db4d5b",
  null
  
)

export default component.exports