import { computed, ComputedRef, Ref, ref } from 'vue';

type CounterComposable = {
	count: Ref<number>;
	showCounter: ComputedRef<boolean>;
	refreshCounter: () => Promise<void>;
};

type CounterComposableProps = {
	counterUrl: string;
	getCount: () => Promise<number>;
	hideCounterAtUrl: boolean;
};

type CounterView = {
	count: Ref<number>;
	showCounter: ComputedRef<boolean>;
	title: string;
	icon: string;
};

type CounterProps = {
	url: string;
	hideCounterAtUrl: boolean;
};

const useCounter = ({ counterUrl, getCount, hideCounterAtUrl }: CounterComposableProps): CounterComposable => {
	const count = ref(0);
	const showCounter = computed(() => count.value > 0);
	const refreshCounter = async () => {
		const currentURL = typeof window !== 'undefined' ? window.location.href : '';
		if (hideCounterAtUrl && (currentURL === counterUrl || typeof window === 'undefined')) {
			count.value = 0;
			return;
		}

		try {
			count.value = await getCount();
		} catch (error) {
			count.value = 0;
		}
	};

	return {
		count,
		showCounter,
		refreshCounter,
	};
};

export { useCounter, CounterComposable, CounterView, CounterProps };
