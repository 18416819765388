import $ from 'jquery';
import { SetupFunction } from 'vue';
import { trans } from '@/domain/dao/Translations';
import { useApiItemCount } from '@/lib/components/Shared/CartIcon.api';
import { useContainer } from '@/domain/services/Container';
import { VueHooks } from '@/domain/services/VueHooks';
import { CounterProps, CounterView, useCounter } from '@/lib/components/Shared/Counter.composable';

const cartIconController: SetupFunction<CounterProps, CounterView> = (props): CounterView => {
	const { onMounted } = useContainer<VueHooks>();
	const { getItemsCount } = useApiItemCount();
	const getCount = async () => (await getItemsCount())?.count || 0;
	const { count, showCounter, refreshCounter } = useCounter({
		counterUrl: props.url,
		getCount,
		hideCounterAtUrl: props.hideCounterAtUrl,
	});

	onMounted(refreshCounter);
	$(document).on('pjax:end', refreshCounter);

	return {
		count,
		showCounter,
		title: trans().get('ecommerce.cart.titles.header'),
		icon: 'af-icons-cart',
	};
};

export { cartIconController };
